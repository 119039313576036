/*eslint-disable*/
import * as actions from "./actionsTypes";
import axios from "../../axios";
import { toast } from "react-toastify";

const initPayment = () => ({
  type: actions.INIT_PAYMENT,
});
const successPayment = (data) => ({
  type: actions.SUCCESS_PAYMENT,
  data,
});
const failPayment = (error) => ({
  type: actions.FAIL_PAYMENT,
  error,
});

export const createPaypalBilling = (data, token) => {
  let execute = null;
  if (data.recurring) {
    execute = axios.post("/paypal/subscription", data, {
      headers: { Authorization: "Bearer " + token },
    });
  } else {
    execute = axios.post("/paypal", data, {
      headers: { Authorization: "Bearer " + token },
    });
  }

  return (dispatch) => {
    dispatch(initPayment());
    execute
      .then((res) => {
        if (
          res.data.message === "You already subscribed to Educate Lanka Fund" ||
          res.data.message === "You already subscribed to this student"
        ) {
          toast.success(res.data.message, {
            autoClose: 15000,
          });
        }
        for (let i = 0; i < res.data.data.links.length; i++) {
          if (
            ["approval_url", "approve"].indexOf(res.data.data.links[i].rel) >= 0
          ) {
            window.location.href = res.data.data.links[i].href;
          }
        }
        return;
      })
      .catch((err) => {
        dispatch(failPayment("Please try again"));
      });
  };
};

export const getPaypalExecute = (data, token) => {
  let reqPath = null;
  if (data.recurring) {
    reqPath = "/paypal/update-sponsorship";
  } else {
    reqPath = "/paypal/execute";
  }

  return (dispatch) => {
    dispatch(initPayment());

    axios
      .post(reqPath, data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        toast.success("Thank you for sponsoring to this student.", {
          autoClose: 15000,
        });
        toast.success("Your payment made successfully", { autoClose: 15000 });
        dispatch(successPayment(res.data));
      })
      .catch((err) => {
        console.error(err);
        dispatch(failPayment(err));
      });
  };
};

export const payWithStripe = (data, token = "") => {
  let successMessage = "Thank you for sponsoring to this student.";

  let execute = null;
  if (data.giftcard) {
    successMessage = "Thank you for creating a giftcard";

    execute = axios.post("/giftcard", data);
  } else if (data.recurring) {
    execute = axios.post("/stripe/subscription", data);
  } else {
    execute = axios.post("/stripe", data);
  }
  return (dispatch) => {
    dispatch(initPayment());

    execute
      .then((res) => {
        if (
          res.data.message === "You already subscribed to Educate Lanka Fund" ||
          res.data.message === "You already subscribed to this student"
        ) {
          dispatch(failPayment(res.data.message));
          toast.success(res.data.message, {
            autoClose: 15000,
          });
        } else {
          dispatch(successPayment(res.data));
        }
      })
      .catch((err) => {
        dispatch(failPayment(err));
      });
  };
};

export const multiPayWithStripe = (data, token = "") => {
  let successMessage = "Thank you for sponsoring to this student.";

  let execute = axios.post("/stripe/bulk/payment", data);
  return (dispatch) => {
    dispatch(initPayment());

    execute
      .then((res) => {
        if (
          res.data.message === "You already subscribed to Educate Lanka Fund" ||
          res.data.message === "You already subscribed to this student"
        ) {
          dispatch(failPayment(res.data.message));
          toast.success(res.data.message, {
            autoClose: 15000,
          });
        } else {
          dispatch(successPayment(res.data));
        }
      })
      .catch((err) => {
        dispatch(failPayment(err));
      });
  };
};

export const payGiftcard = (data, token) => {
  let exicute = null;

  return (dispatch) => {
    dispatch(initPayment());
    axios
      .post("/giftcard/payment", data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        dispatch(successPayment(res.data));
      })
      .catch((err) => {
        dispatch(failPayment(err));
      });
  };
};

export const payOffline = (data, token) => {
  return (dispatch) => {
    dispatch(initPayment());
    axios
      .post("/payment/offline", data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        toast.success("Offline payment successful.", { autoClose: 15000 });
        dispatch(successPayment(res.data));
      })
      .catch((err) => {
        dispatch(failPayment(err));
      });
  };
};
export const resetPayment = () => ({
  type: actions.RESET_PAYMENT,
});
