import React from "react";
import { NavHashLink } from "react-router-hash-link";
import "./Button.scss";

export function Button(props) {
  const classList = ["Button"];
  if (props.full) {
    classList.push("full");
  }
  if (props.size) {
    classList.push(props.size);
  }
  if (props.color) {
    classList.push(props.color);
  } else {
    classList.push("orange");
  }

  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={classList.join(" ")}
      id={props.id}
    >
      {props.title && props.title}
    </button>
  );
}
export function ButtonBlue(props) {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={`blueButton ${props.full ? "full" : null}`}
    >
      {props.title && props.title}
    </button>
  );
}
export function AnchorButton(props) {
  const classList = ["Button"];
  if (props.full) {
    classList.push("full");
  }
  if (props.size) {
    classList.push(props.size);
  }
  if (props.color) {
    classList.push(props.color);
  } else {
    classList.push("orange");
  }
  return (
    <NavHashLink
      to={props.to ? props.to : "#"}
      disabled={props.disabled}
      className={classList.join(" ")}
    >
      {props.title && props.title}
    </NavHashLink>
  );
}
export function LinkLineButton(props) {
  return (
    <NavHashLink to={props.to ? props.to : "#"} className="LinkLineButton">
      {props.title && props.title}
    </NavHashLink>
  );
}
export function LinkLineButtonBlue(props) {
  return (
    <NavHashLink
      to={props.to ? props.to : "#"}
      className="LinkLineButton Blue sm"
    >
      {props.title && props.title}
    </NavHashLink>
  );
}
export function ButtonBlueLine(props) {
  return (
    <button
      className="LinkLineButton Blue sm"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.title && props.title}
    </button>
  );
}
export function ButtonMultiPay(props) {
  return (
    <button
      className="LinkLineButton Green sm"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.title && props.title}
    </button>
  );
}
export function PaypalButton(props) {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={`payButton PaypalButton ${props.lg ? "full" : null}`}
    >
      {props.title && props.title} <i className="fa fa-cc-paypal "></i>
      {props.subTitle && <span>{props.subTitle}</span>}
    </button>
  );
}
export function CardPayButton(props) {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={`payButton CardPayButton ${props.lg ? "full" : null}`}
    >
      {props.title && props.title}
      <i className="fa fa-credit-card "></i>
      {props.subTitle && <span>{props.subTitle}</span>}
    </button>
  );
}

export function GiftButton(props) {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={`payButton GiftButton ${props.lg ? "full" : null}`}
    >
      {props.title && props.title} <i className="fa fa-gift "></i>
      {props.subTitle && <span>{props.subTitle}</span>}
    </button>
  );
}

export function ButtonLargeText(props) {
  const classList = ["OrangeButtonLargeText"];
  if (props.full) {
    classList.push("full");
  }

  if (props.color) {
    classList.push(props.color);
  } else {
    classList.push("orange");
  }

  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={classList.join(" ")}
    >
      {props.title && props.title}
    </button>
  );
}
export function LinkButtonLargeText(props) {
  const classList = ["OrangeButtonLargeText"];
  if (props.full) {
    classList.push("full");
  }

  if (props.color) {
    classList.push(props.color);
  } else {
    classList.push("orange");
  }

  return (
    <NavHashLink to={props.to ? props.to : "#"} className={classList.join(" ")}>
      {props.title && props.title}
    </NavHashLink>
  );
}
