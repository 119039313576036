/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./SponsorshipSummary.scss";
import { useSelector } from "react-redux";
import Loading from "../../../Reusable/Loading/Loading";
import moment from "moment";

const dollerList = [
  { label: "Custom", value: "custom", name: "dollerList" },
  { label: "5%", value: 5, name: "dollerList" },
  { label: "10%", value: 10, name: "dollerList" },
  { label: "15%", value: 15, name: "dollerList" },
  { label: "20%", value: 20, name: "dollerList" },
  { label: "25%", value: 25, name: "dollerList" },
];

const SponsorshipSummary = (props) => {
  const { data: sponsor } = useSelector((state) => state.sponsor);
  const data = sponsor?.sponsorships.map((item) => ({
    studentName: `${item.beneficiary?.firstName} ${
      item.beneficiary?.lastName || ""
    }`.trim(),
    beneficiaryId: item.beneficiaryId,
    status: item.status,
    image: item.beneficiary?.image,
    lastPayment:
      item.payments.length === 0
        ? "-"
        : item.payments[item.payments.length - 1].createdAt,
    nextPayment:
      item.payments.length === 0
        ? "-"
        : item.payments[item.payments.length - 1].dueDate,
    type: item.recurring ? "recurring" : "not-recurring",
    paymentValue: `${item.amount}$`,
  }));

  const { meLoading, loading } = useSelector((state) => state.auth);
  const isLoading = meLoading || loading;

  const [selectedRows, setSelectedRows] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [selectedTip, setSelectedTip] = useState(10);
  const [totalPaymentWithTip, setTotalPaymentWithTip] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [customTip, setCustomTip] = useState("");
  const [totalTip, setTotalTip] = useState(0);

  const activeStudents = data;

  const handleCheckboxChange = (studentName) => {
    const updatedSelectedRows = selectedRows.includes(studentName)
      ? selectedRows.filter((name) => name !== studentName)
      : [...selectedRows, studentName];

    setSelectedRows(updatedSelectedRows);
    const selectableStudents = activeStudents
      .filter((student) => student.type !== "recurring")
      .map((student) => student.studentName);

    setIsAllSelected(
      selectableStudents.length > 0 &&
        updatedSelectedRows.length === selectableStudents.length
    );
  };

  const handleHeaderCheckboxChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const selectableStudents = activeStudents
        .filter((student) => student.type !== "recurring")
        .map((student) => student.studentName);
      setSelectedRows(selectableStudents);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleTipChange = (selectedOption) => {
    if (selectedOption.value === "custom") {
      setSelectedTip("custom");
      setCustomTip("");
      selectedTip(0);
    } else {
      setSelectedTip(selectedOption.value);
    }
  };

  const handleNextClick = () => {
    const selectedData = data.filter(
      (student) =>
        selectedRows.includes(student.studentName) &&
        (student.status === "pending" || student.status === "active")
    );
    const rowsWithDefaults = selectedData.map((student) => ({
      ...student,
      paymentPeriod: "1",
      totalPayment: student.paymentValue,
    }));
    setUpdatedRows(rowsWithDefaults);
    setCurrentStep(2);
  };

  const handleBackClick = () => {
    setCurrentStep(1);
  };

  const handlePeriodChange = (studentName, period) => {
    setUpdatedRows((prevRows) =>
      prevRows.map((row) => {
        if (row.studentName === studentName) {
          const numericValue = parseFloat(row.paymentValue.replace("$", ""));

          return {
            ...row,
            paymentPeriod: period,
            totalPayment: `$${(numericValue * parseInt(period)).toFixed(2)}`,
          };
        }
        return row;
      })
    );
  };

  const calculateTotalPaymentWithTip = () => {
    const total = updatedRows.reduce((acc, row) => {
      const numericValue = parseFloat(row.totalPayment.replace("$", ""));
      return acc + numericValue;
    }, 0);

    const tipPercentage =
      selectedTip === "custom" ? (customTip ? customTip : 0) : selectedTip;

    setTotalTip((total * tipPercentage) / 100);
    const totalWithTip = total + (total * tipPercentage) / 100;
    setTotalPaymentWithTip(parseFloat(totalWithTip.toFixed(2)));
  };

  useEffect(() => {
    if (selectedTip === "custom") {
      calculateTotalPaymentWithTip();
    }
  }, [selectedTip, customTip]);

  useEffect(() => {
    calculateTotalPaymentWithTip();
  }, [selectedTip, updatedRows]);

  useEffect(() => {
    if (!props.show) {
      setSelectedRows([]);
      setUpdatedRows([]);
      setCurrentStep(1);
      setIsAllSelected(false);
      setSelectedTip(10);
      setCustomTip("");
    }
  }, [props.show]);

  const handleCustomTipChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setCustomTip(10);
      setSelectedTip(10);
      return;
    }

    const parsedValue = parseFloat(value);
    s;
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setCustomTip(value);
    }
  };

  return (
    <Modal className="MultiStudenModal" {...props}>
      {isLoading && <Loading />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Sponsorship(s) Summary
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentStep === 1 && (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="tabledata" style={{ minWidth: "120px" }}>
                      Student
                    </th>
                    <th style={{ minWidth: "100px" }}>Status</th>
                    <th style={{ minWidth: "120px" }}>Last Payment</th>
                    <th style={{ minWidth: "120px" }}>Payment Due</th>
                  </tr>
                </thead>
                <tbody>
                  {activeStudents?.length > 0 ? (
                    activeStudents?.map((student, index) => (
                      <tr key={index}>
                        <td>
                          <img
                            src={student.image}
                            alt={student.studentName}
                            className="student-img"
                          />{" "}
                          {student.studentName}
                        </td>
                        <td
                          className={`status-text ${
                            student.status === "active"
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {student.status.charAt(0).toUpperCase() +
                            student.status.slice(1)}
                        </td>
                        <td>
                          {student.lastPayment === "-"
                            ? "-"
                            : moment
                                .utc(student.lastPayment)
                                .local()
                                .format("YYYY-MM-DD")}
                        </td>
                        <td
                          className={
                            new Date(student.nextPayment) < new Date()
                              ? "text-danger"
                              : ""
                          }
                        >
                          {student.type === "recurring" ? (
                            <span className="btn btn-success btn-rounded">
                              Recurring
                            </span>
                          ) : student.nextPayment === "-" ? (
                            "-"
                          ) : (
                            moment
                              .utc(student.nextPayment)
                              .local()
                              .format("YYYY-MM-DD")
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan="5">No Students Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SponsorshipSummary;
