import React, { useState, useEffect, useMemo } from "react";
import { Modal, Row, Col, Alert } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import valid from "card-validator";

import { Button, ButtonBlueLine } from "../../Button/Button";
import { multiPayWithStripe, resetPayment } from "../../../../store/actions";
import Loading from "../../Loading/Loading";
import useYupValidationResolver from "../../../../utils/useYupValidationResolver";
import "./MultiPayStripe.scss";
import LogoIco from "../../../../assets/elf-logo-clr.png";
import addThousandSeparator from "../../../../utils/addThousandSeparator";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

const MultiPayStripe = (props) => {
  const { list, amount, tip, setCurrentStep } = props;

  const { token } = useSelector((state) => state.auth);
  const payment = useSelector((state) => state.payment);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const validationSchema = useMemo(() => {
    const validateObj = {};
    validateObj.card = yup
      .mixed()
      .test("is-card-complete", "Card details are incomplete", (val) => {
        return val?.complete || false;
      })
      .required("Card details are required");
    return yup.object(validateObj);
  }, []);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    control,
    values,
  } = useForm({ resolver });

  const [data, setData] = useState(null);

  useEffect(() => {
    return () => dispatch(resetPayment());
  }, []);

  useEffect(() => {
    if (payment.data?.length > 0) {
      toast.success("Payment Complete successfully!");
      props.setMultiStudentModal(false);
      props?.getUserDetails();
    }
  }, [payment.data]);

  const handleDataFormSubmit = async (data) => {
    const obj = data;
    obj.card = null;
    obj.amount = amount;
    obj.tip = tip;
    obj.list = list.map((student) => {
      return {
        beneficiaryId: student.beneficiaryId,
        month: parseInt(student.paymentPeriod),
        tip: parseFloat(tip * (student.paymentValue.replace("$", "")*student.paymentPeriod / (amount - tip))).toFixed(
          2
        ),
      };
    });

    if (!stripe || !elements) {
      console.error("Stripe has not loaded yet.");
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token: stripeToken } = await stripe.createToken(cardElement);

    if (error) {
      console.error("Error creating token:", error);
    } else {
      // Send the token.id to your backend for further processing
      obj.stripeTokenId = stripeToken.id;
    }
    dispatch(multiPayWithStripe(obj));
  };

  let button = (
    <Button title={`Pay $${addThousandSeparator(amount)}`} full lg />
  );

  let content = (
    <>
      {payment.error && <Alert variant="danger">Payment incompleted.</Alert>}
      <form onSubmit={handleSubmit(handleDataFormSubmit)}>
        <Row>
          <Col md={12} className="inputContainer icon">
            {errors?.card && (
              <p className="errorMessage">{errors.card.message}</p>
            )}
            <Controller
              name="card"
              control={control}
              render={({ field }) => (
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              )}
            />
          </Col>
        </Row>

        {button}
      </form>
      <br />
      <br />
    </>
  );

  const modalHeaderContent = () => {
    return (
      <>
        <h3>Multiple Sponsorship(s) Payment</h3>
        <small>
          Investing in their future, one deserving student at a time.
        </small>
      </>
    );
  };

  return (
    <div className="QuickPay">
      {payment.loading && <Loading />}
      <div className="d-flex justify-content-start">
        <ButtonBlueLine title="Back" onClick={() => setCurrentStep(2)} />
      </div>
      {/* <div className="TitleContent">
        <h5>Educate Lanka</h5>
        {modalHeaderContent()}
      </div> */}
      <div className="pt-4">{content}</div>
    </div>
  );
};

export default MultiPayStripe;
