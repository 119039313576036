/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import {
  ButtonBlueLine,
  CardPayButton,
  PaypalButton,
} from "../../../Reusable/Button/Button";
import "./MultiStudenModal.scss";
import { useSelector } from "react-redux";
import Loading from "../../../Reusable/Loading/Loading";
import Select from "react-select";
import moment from "moment";
import MultiPayStripe from "../../../Reusable/Modal/MultiPayment/MultiPayStripe";

const dollerList = [
  { label: "Custom", value: "custom", name: "dollerList" },
  { label: "5%", value: 5, name: "dollerList" },
  { label: "10%", value: 10, name: "dollerList" },
  { label: "15%", value: 15, name: "dollerList" },
  { label: "20%", value: 20, name: "dollerList" },
  { label: "25%", value: 25, name: "dollerList" },
];

const MultiStudenModal = (props) => {
  const { data: sponsor } = useSelector((state) => state.sponsor);
  const data = sponsor?.sponsorships.map((item) => ({
    studentName: `${item.beneficiary?.firstName} ${
      item.beneficiary?.lastName || ""
    }`.trim(),
    beneficiaryId: item.beneficiaryId,
    status: item.status,
    image: item.beneficiary?.image,
    lastPayment:
      item.payments.length === 0
        ? "-"
        : item.payments[item.payments.length - 1].createdAt,
    nextPayment:
      item.payments.length === 0
        ? "-"
        : item.payments[item.payments.length - 1].dueDate,
    type: item.recurring ? "recurring" : "not-recurring",
    paymentValue: `${item.amount}$`,
  }));

  const { meLoading, loading } = useSelector((state) => state.auth);
  const isLoading = meLoading || loading;

  const [selectedRows, setSelectedRows] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [selectedTip, setSelectedTip] = useState(10);
  const [totalPaymentWithTip, setTotalPaymentWithTip] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [customTip, setCustomTip] = useState("");
  const [totalTip, setTotalTip] = useState(0);

  const activeStudents =
    data?.filter(
      (student) =>
        (student.status === "pending" || student.status === "active") &&
        student.type !== "recurring"
    ) || [];

  const handleCheckboxChange = (studentName) => {
    const updatedSelectedRows = selectedRows.includes(studentName)
      ? selectedRows.filter((name) => name !== studentName)
      : [...selectedRows, studentName];

    setSelectedRows(updatedSelectedRows);
    const selectableStudents = activeStudents
      .filter((student) => student.type !== "recurring")
      .map((student) => student.studentName);

    setIsAllSelected(
      selectableStudents.length > 0 &&
        updatedSelectedRows.length === selectableStudents.length
    );
  };

  const handleHeaderCheckboxChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const selectableStudents = activeStudents
        .filter((student) => student.type !== "recurring")
        .map((student) => student.studentName);
      setSelectedRows(selectableStudents);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleTipChange = (selectedOption) => {
    if (selectedOption.value === "custom") {
      setSelectedTip("custom");
      setCustomTip("");
      selectedTip(0);
    } else {
      setSelectedTip(selectedOption.value);
    }
  };

  const handleNextClick = () => {
    const selectedData = data.filter(
      (student) =>
        selectedRows.includes(student.studentName) &&
        (student.status === "pending" || student.status === "active")
    );
    const rowsWithDefaults = selectedData.map((student) => ({
      ...student,
      paymentPeriod: "1",
      totalPayment: student.paymentValue,
    }));
    setUpdatedRows(rowsWithDefaults);
    setCurrentStep(2);
  };

  const handleBackClick = () => {
    setCurrentStep(1);
  };

  const handlePeriodChange = (studentName, period) => {
    setUpdatedRows((prevRows) =>
      prevRows.map((row) => {
        if (row.studentName === studentName) {
          const numericValue = parseFloat(row.paymentValue.replace("$", ""));

          return {
            ...row,
            paymentPeriod: period,
            totalPayment: `$${numericValue * parseInt(period)}`,
          };
        }
        return row;
      })
    );
  };

  const calculateTotalPaymentWithTip = () => {
    const total = updatedRows.reduce((acc, row) => {
      const numericValue = parseFloat(row.totalPayment.replace("$", ""));
      return acc + numericValue;
    }, 0);

    const tipPercentage =
      selectedTip === "custom" ? (customTip ? customTip : 0) : selectedTip;

    setTotalTip((total * tipPercentage) / 100);
    const totalWithTip = total + (total * tipPercentage) / 100;
    setTotalPaymentWithTip(parseFloat(totalWithTip.toFixed(2)));
  };

  useEffect(() => {
    if (selectedTip === "custom") {
      calculateTotalPaymentWithTip();
    }
  }, [selectedTip, customTip]);

  useEffect(() => {
    calculateTotalPaymentWithTip();
  }, [selectedTip, updatedRows]);

  useEffect(() => {
    if (!props.show) {
      setSelectedRows([]);
      setUpdatedRows([]);
      setCurrentStep(1);
      setIsAllSelected(false);
      setSelectedTip(10);
      setCustomTip("");
    }
  }, [props.show]);

  const handleCustomTipChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setCustomTip(10);
      setSelectedTip(10);
      return;
    }

    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setCustomTip(value);
    }
  };

  return (
    <Modal className="MultiStudenModal" {...props}>
      {isLoading && <Loading />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Pay for Multiple Student(s)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentStep === 1 && (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {activeStudents?.length > 0 && (
                      <th style={{ width: "70px" }}>
                        <input
                          type="checkbox"
                          checked={isAllSelected}
                          onChange={handleHeaderCheckboxChange}
                        />{" "}
                        All
                      </th>
                    )}
                    <th className="tabledata" style={{ minWidth: "120px" }}>
                      Student
                    </th>
                    <th style={{ minWidth: "100px" }}>Status</th>
                    <th style={{ minWidth: "120px" }}>Last Payment</th>
                    <th style={{ minWidth: "120px" }}>Payment Due</th>
                  </tr>
                </thead>
                <tbody>
                  {activeStudents?.length > 0 ? (
                    activeStudents.map((student, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            disabled={student.type === "recurring"}
                            checked={selectedRows.includes(student.studentName)}
                            onChange={() =>
                              handleCheckboxChange(student.studentName)
                            }
                          />
                        </td>
                        <td>
                          <img
                            src={student.image}
                            alt={student.studentName}
                            className="student-img"
                          />{" "}
                          {student.studentName}
                        </td>
                        <td
                          className={`status-text ${
                            student.status === "active"
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {student.status.charAt(0).toUpperCase() +
                            student.status.slice(1)}
                        </td>
                        <td>
                          {student.lastPayment === "-"
                            ? "-"
                            : moment
                                .utc(student.lastPayment)
                                .local()
                                .format("YYYY-MM-DD")}
                        </td>
                        <td
                          className={
                            new Date(student.nextPayment) < new Date()
                              ? "text-danger"
                              : ""
                          }
                        >
                          {student.type === "recurring" ? (
                            <span className="btn btn-success btn-rounded">
                              Recurring
                            </span>
                          ) : student.nextPayment === "-" ? (
                            "-"
                          ) : (
                            moment
                              .utc(student.nextPayment)
                              .local()
                              .format("YYYY-MM-DD")
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan="5">No Students Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {activeStudents?.length > 0 && (
              <div className="d-flex justify-content-end pr-1 mt-4">
                <ButtonBlueLine
                  title="Next"
                  disabled={selectedRows.length === 0}
                  onClick={handleNextClick}
                />
              </div>
            )}
          </>
        )}

        {currentStep === 2 && (
          <>
            <div className="d-flex justify-content-start">
              <ButtonBlueLine title="Back" onClick={handleBackClick} />
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Student Name</th>
                    <th>Monthly Amount</th>
                    <th>Payment Period</th>
                    <th className="d-flex justify-content-end">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {updatedRows.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={row.image}
                          alt={row.studentName}
                          className="student-img"
                        />
                        {"  "}
                        {row.studentName}
                      </td>
                      <td>{row.paymentValue}</td>
                      <td>
                        <div className="select-wrapper">
                          <Select
                            value={{
                              label:
                                row.paymentPeriod === "1"
                                  ? "1 Month"
                                  : row.paymentPeriod === "12"
                                  ? "1 Year"
                                  : `${row.paymentPeriod} Months`,
                              value: row.paymentPeriod,
                            }}
                            onChange={(selectedOption) =>
                              handlePeriodChange(
                                row.studentName,
                                selectedOption.value
                              )
                            }
                            options={[
                              { value: "1", label: "1 Month" },
                              { value: "3", label: "3 Months" },
                              { value: "6", label: "6 Months" },
                              { value: "12", label: "1 Year" },
                            ]}
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                width: "150px",
                              }),
                              menuPortal: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            menuPortalTarget={document.body}
                            menuPosition="absolute"
                            menuPlacement="auto"
                          />
                        </div>
                      </td>
                      <td className="d-flex justify-content-end">
                        {row.totalPayment || row.paymentValue}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="table-responsive mt-4">
              <table className="table">
                <tbody>
                  <tr>
                    <td>Tip:</td>
                    <td className="d-flex justify-content-end">
                      <span className="optianalSpan">
                        {selectedTip === "custom" ? (
                          <div className="tip-input-wrapper">
                            <input
                              className="tip-input form-control"
                              type="number"
                              value={customTip}
                              onChange={handleCustomTipChange}
                              style={{ width: "100px" }}
                              min="0"
                            />
                          </div>
                        ) : (
                          <div className="select-wrapper">
                            <Select
                              value={dollerList.find(
                                (option) => option.value === selectedTip
                              )}
                              onChange={handleTipChange}
                              options={dollerList}
                              styles={{
                                container: (provided) => ({
                                  ...provided,
                                  width: "100px",
                                }),
                                menuPortal: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              menuPortalTarget={document.body}
                              menuPosition="absolute"
                              menuPlacement="auto"
                            />
                          </div>
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Total with Tip:</td>
                    <td className="d-flex justify-content-end">
                      <strong>${totalPaymentWithTip}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <hr />
            <Row className="justify-content-center mb-3 mt-3">
              <Col md={12}>
                <CardPayButton
                  title={"Pay with"}
                  subTitle={"Pay with Credit Card"}
                  lg
                  onClick={() => setCurrentStep(3)}
                />
              </Col>
              {/* <Col md={6}>
                <PaypalButton title={"Pay with"} subTitle={"Pay with PayPal"} lg />
              </Col> */}
            </Row>
          </>
        )}
        {currentStep === 3 && (
          <MultiPayStripe
            setCurrentStep={setCurrentStep}
            list={updatedRows}
            amount={totalPaymentWithTip}
            tip={totalTip}
            setMultiStudentModal={props.setMultiStudentModal}
            getUserDetails={props?.getUserDetails}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MultiStudenModal;
